.hover-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
  pointer-events: none;
}

.video-react-big-play-button {
  display: none;
}

.video {
  padding: 0px !important;
  height: 100% !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  z-index: 0;
  overflow: hidden;
}

video {
  -o-object-fit: cover !important;
  object-fit: cover !important;
  z-index: 0;
  height: 100% !important;
  width: 100% !important;
}


@media only screen and (max-width: 430px) {
  .video {
    height: 100% !important;
    -o-object-fit: cover !important;
    object-fit: cover !important;
    overflow: hidden;
  }

  .replay {
    margin-left: 85% !important;
    margin-top: 65% !important;
  }

  .foward {
    margin-left: 85% !important;
    margin-top: 50% !important;
  }
}

@media only screen and (min-width:480px) {

  .replay {
    margin-left: 85% !important;
    margin-top: 32% !important;
  }

  .foward {
    margin-left: 85% !important;
    margin-top: 22% !important;
  }
}

@media only screen and (min-width:720px) {

  .replay {
    margin-left: 85% !important;
    margin-top: 27% !important;
  }

  .foward {
    margin-left: 85% !important;
    margin-top: 19% !important;
  }
}

@media only screen and (min-width:1200px) {

  .replay {
    margin-left: 85% !important;
    margin-top: 16% !important;
  }

  .foward {
    margin-left: 85% !important;
    margin-top: 12% !important;
  }
}

.player-container {
  position: absolute;
  height: 100%;
  width: 100%;
}

.replay {
  position: absolute !important;
  color: #fffefee3 !important;
  background: #0000004f !important;
  border-color: rgba(255, 255, 255, 0.795) !important;
  margin-left: 85%;
  margin-top: 33.5%;
  z-index: 500;
  border-radius: 50%;
  box-shadow: #0000005a 1px 1px 3px;
  font-size: 3rem !important;
  padding: 6px;
  overflow: visible;
  border-width: 2px;
}

.replay:hover {
  position: absolute !important;
  color: #ffffff !important;
}

.foward {
  position: absolute !important;
  color: #fffefee3 !important;
  background: #0000004f !important;
  border-color: rgba(255, 255, 255, 0.795) !important;
  margin-left: 78%;
  margin-top: 15%;
  margin-bottom: 7px;
  margin-right: 7px;
  z-index: 500;
  border-radius: 50%;
  box-shadow: #0000005a 1px 1px 3px;
  font-size: 3rem !important;
  overflow: visible;
  border-width: 2px;
  padding: 4px;
}

.foward:hover {
  position: absolute !important;
  color: #ffffff !important;
}